<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <template>
      <v-data-table :headers="tableHeader" :items="users" sort-by="FullName" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Manage Administrators</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogUser" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  New Administrator
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="user.name" :rules="nameRules" label="Full Name" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.email" :rules="emailRules"  label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="editedUID == -1">
                        <v-text-field v-model="user.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" type="submit" text>
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteUser(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </template>

    <template>
      <v-data-table :headers="tableHeaderDemo" :items="usersDemo" sort-by="ExpirationDate" class="elevation-1 mt-5 tableDemo">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="orange--text">Teachers who requested a demo</v-toolbar-title>
            <v-divider class="mx-4 orange" inset vertical></v-divider>
            <v-spacer></v-spacer>            
          </v-toolbar>
        </template>
        <template v-slot:item.RegistrationDate="{ item }">
          {{ item.RegistrationDate!=undefined && item.RegistrationDate!=""?item.RegistrationDate.toDate().toISOString().slice(0,10):"" }}
        </template>
        <template v-slot:item.ExpirationDate="{ item }">
          {{ item.ExpirationDate!=undefined && item.ExpirationDate!=""?item.ExpirationDate.toDate().toISOString().slice(0,10):"" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">          
          <v-icon small class="mr-2 green--text" @click="createDemoUser(item)" v-if="!item.Approved">
            mdi-account-plus
          </v-icon>
          <v-icon small class="mr-2 orange--text" @click="deleteUserConfirmTeacher(item)" v-else>
            mdi-delete-forever
          </v-icon>
          <v-icon small class="mr-2 red--text" @click="deleteUserConfirmTeacher(item)" v-show="!item.Approved">
            mdi-delete-variant
          </v-icon>          
        </template>
        
      </v-data-table>
    </template>

    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteTeacher" max-width="500px">
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <v-toolbar-title>Seguro de eliminar:  {{ userTeacher.Name}} - {{userTeacher.Email}}?</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="!userTeacher.Approved" class="text-center"><strong>Este usuario no fue registrado como un usuario Demo.</strong></v-card-text>
        <v-card-text v-else class="text-center"><strong>Este usuario es un usuario Demo.</strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteTeacher">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteOrderDemoUser(userTeacher)" v-if="!userTeacher.Approved">Yes</v-btn>
          <v-btn color="blue darken-1" text @click="deleteDemoUser(userTeacher)" v-else>Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmCreation" max-width="520px">
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <v-toolbar-title>Seguro de que desea crear el usuario demo:  {{ userTeacher.Name}} ?</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-left">Se creara un maestro demo en Maestros interesados.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirmCreation">Cancel</v-btn>          
          <v-btn color="blue darken-1" text @click="CreateTeacher(userTeacher)">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, functions, auth } from '../../../plugins/firebase'
import firebase from 'firebase'
import 'firebase/functions'
export default {
  data () {
    return {
      tableHeader: [
        { text: 'FullName', value: 'Name' },
        { text: 'Email', value: 'Email' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      users: [],
      user: {
        name: '',
        email: '',
        password: '',
      },
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      clearUser: {
        name: '',
        email: '',
        password: '',
      },
      editedUID: -1,
      dialogUser: false,
      dialogDelete: false,
      dialogDeleteTeacher: false,
      dialogConfirmCreation: false,
      dialogLoad: false,
      userTeacher: {},
      approvedUser: false,
      valid: true,
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      table: 'SuperAdmin',
      tableHeaderDemo: [
        { text: 'Name', value: 'Name' },
        { text: 'LastName', value: 'LastName' },
        { text: 'Email', value: 'Email' },
        { text: 'Organization Email', value: 'SchoolEmail' },
        { text: 'School', value: 'School' },
        { text: 'DistrictSchool', value: 'DistrictSchool' },
        { text: 'Registration Date', value: 'RegistrationDate' },
        { text: 'Expiration Date', value: 'ExpirationDate' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      usersDemo: [],
      filterDomain: ['gmail.com', 'googlemail.com', 'outlook.com', 'hotmail.com', 'live.com', 'yahoo.com', '', 'ymail.com', 'rocketmail.com', 'protonmail.com', 'zoho.com', 'zohomail.com', 'aol.com', 'aim.com', 'mail.com']
    }
  },
  created () {
    this.Users();
    this.loadDemoUsers();
    // db.collection("Students").where("Classrooms.MisionActual", "==", 7).get()
    // .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         console.log(doc.data());
    //     });
    // })
    // .catch((error) => {
    //     console.log("Error getting documents: ", error);
    // });
    // var agregar =functions.httpsCallable('crearUsuarioUidPersonalizado');
    // agregar().then((user) => {
    //         console.log(user)
    //       })
    // this.BuscarVersionAntigua();

    // this.BuscarCodigoClase();
  },
  methods: {
    async BuscarVersionAntigua(){
      // let querySnapshot= await db.collection("SchoolTeacher").where("Classrooms", "!=", {}).get() 
      // querySnapshot.forEach((doc) => {       
      //   let ids = Object.keys(doc.data().Classrooms);
      //  for (const element of ids) {
      //   if (doc.data().Classrooms[element].NumberGroup>0) {
      //        db.collection("SchoolTeacher").doc(doc.data().UserUID).collection("Classrooms").doc(element).get().then((clase)=>{
      //           // console.log(clase.data()); 
      //          let idsGroup = Object.keys(clase.data().Groups);
      //          if (idsGroup.length>0) {
      //           for (const idGroup of idsGroup) {
      //             if (Object.prototype.hasOwnProperty.call(clase.data().Groups[idGroup], 'Students')) {
      //               console.log(clase.data().Groups[idGroup]);
      //             }
      //           }
      //          }
      //        })
      //      }
      //    }
      //  });
     },
      async BuscarCodigoClase(){
      // let querySnapshot= await db.collection("SchoolTeacher").where("Classrooms", "!=", {}).get() 
      // querySnapshot.forEach((doc) => { 
      //   if (doc.data().TypeLogin == 'clever') {
      //     let ids = Object.keys(doc.data().Classrooms);
      //     for (const element of ids) {
      //       db.collection("SchoolTeacher").doc(doc.data().UserUID).collection("Classrooms").doc(element).get().then((clase)=>{
      //         let codigoClase = 'HQU6U';
      //         if (clase.data().CodeAccess == codigoClase) {
      //           console.log(doc.data());
      //         } 
      //       })
      //     }
      //   }

      //  })
     },
    editUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogUser = true
    },
    deleteUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogDelete = true
    },
    deleteUserConfirm () {
      this.deleteData(this.editedUID).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "deleted successfully";
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDelete()
    },
    close () {
      this.dialogUser = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    save () {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.user).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "updated successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'not found user';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.user).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "created successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.close()
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData (user) {
      let answer = { result: '', message: '' }
      try {
        user.table = this.table;
        const setAdmin = functions.httpsCallable('createUserPrivate')
        await setAdmin(user)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async updateData (user) {
      let answer = { result: '', message: '' }
      try {
        user.table = this.table;
        const updatetAdmin = functions.httpsCallable('updateUserPrivate')
        await updatetAdmin(user)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async Users () {
      console.log("recibiendo usuarios");
      await db.collection(this.table).onSnapshot((snapshotChange) => {
        this.users = [];
        snapshotChange.forEach((doc) => {
          console.log(doc.data());
          let oneStudent = doc.data();
          oneStudent.id = doc.id;
          this.users.push(oneStudent);
        });
      }, (error) => {
        console.log(error);
      })
    },
    async deleteData (id) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        const deleteAdmin = functions.httpsCallable('deleteUserAdmin')
        await deleteAdmin(id)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    async loadDemoUsers(){
      await db.collection("OrderForDemoUser").where("Role", "==", "teacher").onSnapshot((snapshotChange) => {
        this.usersDemo = [];
        snapshotChange.forEach((doc) => {                 
          const docData = doc.data();
          docData.id = doc.id;
          this.usersDemo.push(docData);
        });
      }, (error) => {
        console.log(error);
      });      
    },
    invalidMail(email){      
      let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(email)) {
        return false; // El formato del correo no es válido
      }
      // Verificar si el correo termina con alguno de los dominios especificados
      for (let i = 0; i < this.filterDomain.length; i++) {
        if (email.endsWith("@" + this.filterDomain[i])) {
          return true; // El correo pertenece a uno de los dominios especificados
        }
      }
      return false;
    },    
    createDemoUser(userD){
      console.log(userD);
      /*if (this.invalidMail(userD.Email)){
        this.alertError = "Invalid email.";
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return;
      }*/
      this.userTeacher = Object.assign({}, userD);
      this.dialogConfirmCreation=true;
      //this.CreateTeacher(userD);      
    },
    CreateTeacher(userD){
      this.dialogLoad = true;
      this.createDataTeacher(userD).then(async response => {
        console.log(response);
        if (!response.result) {
          this.alertError = response.message;
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
        } else {
          const confirmDemo = functions.httpsCallable('confirmDemo');
          const resConfirm=await confirmDemo({recipient: userD.Email, user: response.user, pwd: response.pwd});
          console.log(resConfirm);
          this.closeConfirmCreation();
          this.alertSuccess = "Usuario demo creado correctamente.";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        }
        this.dialogLoad = false
      });
    },
    async emailExists(email){
      //https://qiita.com/zaburo/items/59baf813b79387da6f74
      let userAuth = await auth().fetchSignInMethodsForEmail(email);
      if (userAuth.findIndex(p => p === auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {        
        return true;
      }      
      return false;      
    },
    async generateUser(name, lastname){
      let user = (name + lastname).toLowerCase().replace(/\s/g, '');
      user=user.slice(0, 10);
      //let regSysTeacher=await this.emailExists(user+'@tedemo.com');
      const emailExists = functions.httpsCallable('emailExists');
      let regSysTeacher=await emailExists({email: user+'@tedemo.com'}); 
      let i=1;
      let newUser=user;
      while(regSysTeacher.data){
        newUser=user+i;
        //regSysTeacher=await this.emailExists(newUser+'@tedemo.com');
        regSysTeacher=await emailExists({email: newUser+'@tedemo.com'});
        i++;
      }
      user=newUser;
      return user;
    },
    async createDataTeacher (teacher) {
      let answer = { result: '', message: '' };
      try {
        let user=await this.generateUser(teacher.Name, teacher.LastName);
        let email=user+'@tedemo.com';
        /*let referenceTeacher = db.doc('SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id);        
        teacher.reference = referenceTeacher.path;*///Aqui falta ver como va a ser
        teacher.reference = 'SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/3udFo7nWv6vQLZYGI0yp/SchoolDistricts/DuomTO0ODAzA5iDxfOyd/Schools/yIo9kpGArJSTVxlWddTq';
        teacher.collection = 'SchoolTeacher';
        teacher.password = user;
        teacher.email = email;
        teacher.name = teacher.Name;
        teacher.lastName = teacher.LastName;
        teacher.school = teacher.School;
        //Calculamos la fecha para dentro de 7 dias
        const today = new Date();
        const sevenDaysLater = new Date(today);
        sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);        
        teacher.dateExpiration = sevenDaysLater.toISOString().slice(0,10);
        let ReferenceGrades = db.doc('SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/GradeData/GradeD');
        teacher.ListGradesReference = ReferenceGrades.path;
        teacher.Multiplayer = false;
        teacher.HasClassrooms = false;
        teacher.isDemo = true;      
        const setTeacher = functions.httpsCallable('createTeacher');
        await setTeacher(teacher);
        const getTeacherData = functions.httpsCallable('getTeacherData');
        let datosTeacher = await getTeacherData({email:teacher.email}); 
        const updDemoUserOrder = functions.httpsCallable('updDemoUserOrder');
        await updDemoUserOrder({id: teacher.id, dateExpiration: teacher.dateExpiration, email:teacher.email, schoolEmail: teacher.Email, uid: datosTeacher.data.uid});     
        
        this.loadDemoUsers();
        answer.result = true;
        answer.pwd = user;
        answer.user = email;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log(error);
      }
      return answer;
    },
    async deleteDemoUser(item){      
      try {
        let reference = db.doc('SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/3udFo7nWv6vQLZYGI0yp/SchoolDistricts/DuomTO0ODAzA5iDxfOyd/Schools/yIo9kpGArJSTVxlWddTq');
        let data = {id: item.IdDemoTe, reference: reference.path};console.log(data);
        const deleteTeacher = functions.httpsCallable('deleteTeacher');
        await deleteTeacher(data);
        await this.deleteOrderDemoUser(item);        
      } catch (error) {        
        console.log(error);
      }      
    },
    async deleteOrderDemoUser(item){
      let answer = { result: '', message: '' };
      try {
        await db.collection('OrderForDemoUser').doc(item.id).delete();
        this.closeDeleteTeacher();
        this.loadDemoUsers();
        this.alertSuccess = "Deleted successfully";
        let main = this;        
        setTimeout(function () { main.alertSuccess = '' }, 4000);
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;        
        console.log(error);
      }
      return answer;
    },
    deleteUserConfirmTeacher(item){
      //this.editedUIDTeacher = teacher.UserUID;
      this.userTeacher = Object.assign({}, item);
      this.dialogDeleteTeacher = true;
    },
    closeDeleteTeacher() {
      this.dialogDeleteTeacher = false;
      this.$nextTick(() => {
        this.userTeacher = {}
      });
    },
    closeConfirmCreation() {
      this.dialogConfirmCreation = false
      this.$nextTick(() => {
        this.userTeacher = {}
      });
    },
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New Administrator' : 'Edit Administrator'
    },
  },
  watch: {
    dialogUser (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
<style>
.tableDemo{
  border: 1px solid orange;
}
.tableDemo tbody tr:nth-of-type(odd) {
    /* 'teal lighten-5' basides on material design color */
    background-color: #E0F2F1;
}

.tableDemo tbody tr:nth-of-type(even) {
    /* 'deep-orange lighten-5' basides on material design color */
    background-color: #FBE9E7;
}
</style>
